var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ju-ke-fen-xi" }, [
    _c(
      "div",
      { staticClass: "chart-wrap" },
      [
        _c("BarChart", {
          attrs: {
            echartData: _vm.echartsData,
            barWidth: 28,
            barColor: _vm.barColor,
            textColor: _vm.textColor,
            yUnit: "%",
            echartSize: _vm.echartSize,
            maxLength: 8,
            isHideY: true,
            isHideYline: true,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }