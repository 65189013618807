var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "site-wrap detail-main" },
    [
      _c("Header", { attrs: { mode: 2, detailType: "品牌" } }),
      _c("MenuSide", {
        attrs: { list: _vm.menuList, activeMenu: _vm.activeMenu },
        on: { isOpen: _vm.isMenuOpen, onselect: _vm.menuSelect },
      }),
      _c(
        "div",
        {
          ref: "scroll",
          staticClass: "main-scroll",
          class: _vm.menuOpen ? "" : "main-scroll-change",
        },
        [
          _c(
            "div",
            { staticClass: "map-size" },
            [
              _vm.mapMarkers.length != 0
                ? _c("MapPanelLittle", { attrs: { markers: _vm.mapMarkers } })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "main-size" },
            [
              _c("SummaryCard", {
                attrs: {
                  cardType: _vm.cardType,
                  cardInfo: _vm.detailInfo,
                  isVip:
                    _vm.userInfo &&
                    _vm.userInfo.userMember &&
                    (_vm.userInfo.userMember.key === "vip" ||
                      _vm.userInfo.userMember.key === "svip")
                      ? true
                      : false,
                  isSVip:
                    _vm.userInfo &&
                    _vm.userInfo.userMember &&
                    _vm.userInfo.userMember.key === "svip"
                      ? true
                      : false,
                  collectShow: false,
                  claimShow: false,
                  shareShow: false,
                  detailID: _vm.brandID,
                },
              }),
              _c(
                "div",
                { staticClass: "content-item", attrs: { id: "supportInfo" } },
                [
                  _c(
                    "div",
                    { staticClass: "item-title" },
                    [
                      _vm._v("配套信息 "),
                      _c(
                        "Poptip",
                        {
                          attrs: {
                            trigger: "hover",
                            placement: "right",
                            padding: "0 0",
                          },
                        },
                        [
                          _c("Icon", {
                            staticClass: "help_icon",
                            attrs: { type: "ios-help-circle-outline" },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "m-tips",
                              attrs: { slot: "content" },
                              slot: "content",
                            },
                            [
                              _c("p", [
                                _c("span", [
                                  _vm._v("门店所在区域周边500米的配套信息"),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("AssortInfo", { attrs: { storeInfo: _vm.detailInfo } }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "content-item", attrs: { id: "yetai" } },
                [
                  _c(
                    "div",
                    { staticClass: "item-title" },
                    [
                      _vm._v(" 业态分布 "),
                      _c(
                        "Poptip",
                        {
                          attrs: {
                            trigger: "hover",
                            placement: "right",
                            padding: "0 0",
                          },
                        },
                        [
                          _c("Icon", {
                            staticClass: "help_icon",
                            attrs: { type: "ios-help-circle-outline" },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "m-tips",
                              attrs: { slot: "content" },
                              slot: "content",
                            },
                            [
                              _c("p", [
                                _c("span", [
                                  _vm._v("门店所在区域周边500米的业态分布"),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "business-size" }, [
                    _c(
                      "div",
                      { staticClass: "business-left" },
                      _vm._l(_vm.echartData1, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: "business_" + index,
                            staticClass: "left-item",
                          },
                          [
                            _c("div", { staticClass: "left-item-title" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            _c("div", { staticClass: "left-item-num" }, [
                              _vm._v(_vm._s(item.value)),
                              _c("span", [_vm._v("家")]),
                            ]),
                            _c("div", { staticClass: "left-item-line" }),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "business-right" },
                      [
                        _c("PieChart", {
                          staticClass: "charts-box",
                          attrs: { echartData: _vm.echartData1 },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "content-item", attrs: { id: "juKeFenXi" } },
                [
                  _c(
                    "div",
                    { staticClass: "item-title" },
                    [
                      _vm._v(" 周边客群 "),
                      _c(
                        "Poptip",
                        {
                          attrs: {
                            trigger: "hover",
                            placement: "right",
                            padding: "0 0",
                          },
                        },
                        [
                          _c("Icon", {
                            staticClass: "help_icon",
                            attrs: { type: "ios-help-circle-outline" },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "m-tips",
                              attrs: { slot: "content" },
                              slot: "content",
                            },
                            [
                              _c("p", [
                                _c("span", [
                                  _vm._v("门店所在区域周边500米的客群分析"),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("Icon", {
                        staticClass: "g-vip-icon",
                        attrs: { type: "ios-lock-outline" },
                      }),
                    ],
                    1
                  ),
                  _c("section", { staticClass: "g-vip-section" }, [
                    _c(
                      "div",
                      {
                        staticClass: "g-vip-btn",
                        attrs: { role: "button" },
                        on: { click: _vm.toVip },
                      },
                      [_vm._v("去升级")]
                    ),
                    _c("div", [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/demo_pointer.png"),
                          width: "464",
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "diliver" }),
                  ]),
                  _vm.detailInfo.geom
                    ? _c("CircumCustomer", {
                        attrs: {
                          id: _vm.brandID,
                          identify: "单品牌",
                          posInfo: _vm.detailInfo,
                        },
                        on: { getTotal: _vm.getTotal },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "chart-title" },
                    [
                      _vm._v(" 聚客类型 "),
                      _c(
                        "Poptip",
                        {
                          attrs: {
                            trigger: "hover",
                            placement: "right",
                            padding: "0 0",
                          },
                        },
                        [
                          _c("Icon", {
                            staticClass: "help_icon",
                            attrs: { type: "ios-help-circle-outline" },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "m-tips",
                              attrs: { slot: "content" },
                              slot: "content",
                            },
                            [
                              _c("p", [
                                _c("span", [
                                  _vm._v(
                                    "门店所在区域周边500米的聚客点主力类型"
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("JuKeFenXi", { attrs: { storeInfo: _vm.detailInfo } }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "demo-item", attrs: { id: "mddf" } },
                [
                  _c("Icon", {
                    staticClass: "g-vip-icon2",
                    attrs: { type: "ios-lock-outline" },
                  }),
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/demo/store_detail_demo5_b.png"),
                      alt: "",
                    },
                  }),
                  _c("div", {
                    staticClass: "demo-btn2",
                    on: { click: _vm.vipClick },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "demo-item", attrs: { id: "kehu" } },
                [
                  _c("Icon", {
                    staticClass: "g-vip-icon3",
                    attrs: { type: "ios-lock-outline" },
                  }),
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/demo/store_detail_demo6_b.png"),
                      alt: "",
                    },
                  }),
                  _c("div", {
                    staticClass: "demo-btn2",
                    on: { click: _vm.vipClick },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "demo-item", attrs: { id: "mdxl" } },
                [
                  _c("Icon", {
                    staticClass: "g-vip-icon2",
                    attrs: { type: "ios-lock-outline" },
                  }),
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/demo/store_detail_demo7_b.png"),
                      alt: "",
                    },
                  }),
                  _c("div", {
                    staticClass: "demo-btn2",
                    on: { click: _vm.vipClick },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "demo-item custom-item",
                  attrs: { id: "depth" },
                },
                [
                  _c(
                    "div",
                    {
                      ref: "offlineAnalysis",
                      staticClass: "anchor-box8",
                      attrs: { id: "offlineAnalysis" },
                    },
                    [_c("offline-analysis")],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "anchor-box9",
                      attrs: { id: "onlineAnalysis" },
                    },
                    [_c("online-analysis")],
                    1
                  ),
                ]
              ),
              _c("div", { staticClass: "page-bottom" }),
              _c("ModalUpgrade", {
                attrs: { isShow: _vm.isShow },
                on: {
                  close: function ($event) {
                    _vm.isShow = false
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }