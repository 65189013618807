var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "assort-info" }, [
    _c(
      "div",
      { staticClass: "left" },
      [
        _c("MapPanel", {
          ref: "mapPanel",
          attrs: {
            id: _vm.mapId,
            mapTools: _vm.mapTools,
            legendsObj: _vm.legendsObj,
            ignoreFitChina: true,
          },
          on: {
            loaded: _vm.handleMapLoaded,
            selectCity: _vm.handleSelectCity,
            startDraw: _vm.handleStartDraw,
            drawPoint: _vm.handleDrawPoint,
            drawPolygon: _vm.handleDrawPolygon,
            searchPoi: _vm.searchPoi,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "right" },
      [
        _c("SupportingFacilities", {
          attrs: { polygon: _vm.resultGeom, address: _vm.markerAddress },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }