//聚客分析
<template>
  <div class="ju-ke-fen-xi">
    <div class="chart-wrap">
      <BarChart
        :echartData="echartsData"
        :barWidth="28"
        :barColor="barColor"
        :textColor="textColor"
        :yUnit="'%'"
        :echartSize="echartSize"
        :maxLength="8"
        :isHideY="true"
        :isHideYline="true"
      >
      </BarChart>
    </div>
  </div>
</template>
<script>
import BarChart from "@/components/chart/barChart.vue";
import { GetCommonInfo } from "@/service";

export default {
  name: "juKeFenXi",
  props: {
    storeInfo: {
      type: Object,
      required: true,
      default: () => {
        return {
          geom: "0101000020E6100000577C43E1B3555E408D9944BDE0173F40",
          lat: "31.093273",
          lon: "121.339104",
          区县名称: "松江区",
          区县编码: "310117",
          品牌分类: "餐饮/小吃快餐/快餐简餐",
          品牌名称: "沙县小吃",
          图片url:
            "https://shuguan-assets.changjing.com.cn/data/brand-v2/67022880-6c6e-4e52-ba43-f0bab992e71d.jpg",
          城市: "上海市",
          城市名称: "上海市",
          市编码: "310100",
          更新时间: "2022-08-17",
          版本号: "v3.2",
          省名称: "上海市",
          省编码: "310000",
          门店id: "3df842df-e472-49d5-8102-cde068257f9e",
          门店名称: "沙县小吃",
          门店地址: "莘松路1464号",
        };
      },
    },
  },
  watch: {
    storeInfo(val, oldVal) {
      const strVal = JSON.stringify(val);
      const strOldVal = JSON.stringify(oldVal);
      if (val && strVal !== strOldVal) {
        this.initData(val);
      }
    },
  },
  data() {
    return {
      barColor: "#3519FB",
      textColor: "#848295",
      echartSize: { x: 40, x2: 10, y: 20, y2: 30 },

      markerObj: {
        center: [116.309834, 40.042785],
        type: "",
        radius: 500,
      },
      polygon: null, //区域位置点位信息
      echartsData: [
        {
          name: "学校",
          key: "school",
          value: 0,
        },
        {
          name: "购物中心",
          key: "business",
          value: 0,
        },
        {
          name: "办公场所",
          key: "office",
          value: 0,
        },
        {
          name: "医疗保健",
          key: "medical",
          value: 0,
        },
        {
          name: "住宅",
          key: "community",
          value: 0,
        },
        {
          name: "交通枢纽",
          key: "bus",
          value: 0,
        },
      ],
    };
  },
  methods: {
    //更新数据
    initData() {
      // const storeInfo = this.storeInfo;
      // this.markerObj.center = [Number(storeInfo.lon), Number(storeInfo.lat)];

      // const { center, radius } = this.markerObj;
      // let circle = turf.circle(center, radius / 1000, {
      //   steps: 256,
      //   units: "kilometers",
      // });

      // this.polygon = JSON.stringify(circle.geometry);
      this.getDataInfo();
      // console.log(this.storeInfo)
    },
    //获取区域数据
    async getDataInfo() {
      // let tempPolygon = geom2wkt(this.polygon)
      let params = {
        identify: "sg-data-品牌-门店聚客分析",
        fields: [
          "标签1",
          "标签2",
          "标签3",
          "标签4",
          "标签5",
          "标签6",

          "标签1比率",
          "标签2比率",
          "标签3比率",
          "标签4比率",
          "标签5比率",
          "标签6比率",
        ],
        filters: [
          {
            field: "key1",
            cond: 0,
            value: [this.storeInfo["门店id"]],
          },
        ],
        pages: {
          page: 1,
          size: 10000,
        },
      };

      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        const temp = res.data.items[0];
        for (let i = 0, leni = this.echartsData.length; i < leni; i++) {
          let tempData = this.echartsData[i];
          tempData.value = temp[i + 6] || 0;
          tempData.value = (tempData.value * 100).toFixed(0);
          this.$set(this.echartsData, i, tempData);
        }
      }
    },
  },
  mounted() {},
  created() {},
  components: { BarChart },
};
</script>
<style lang="less" scoped>
.ju-ke-fen-xi {
  position: relative;
  height: 317px;
  width: 100%;
  box-sizing: border-box;
  padding: 27px 30px;
  .title {
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);
    line-height: 20px;
  }
  .chart-wrap {
    position: relative;
    height: 100%;
    width: 100%;
  }
}
</style>
