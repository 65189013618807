<template>
  <div class="site-wrap detail-main">
    <Header :mode="2" detailType="品牌"></Header>
    <MenuSide
      :list="menuList"
      :activeMenu="activeMenu"
      @isOpen="isMenuOpen"
      @onselect="menuSelect"
    ></MenuSide>
    <div
      class="main-scroll"
      ref="scroll"
      :class="menuOpen ? '' : 'main-scroll-change'"
    >
      <div class="map-size">
        <MapPanelLittle
          :markers="mapMarkers"
          v-if="mapMarkers.length != 0"
        ></MapPanelLittle>
      </div>
      <div class="main-size">
        <!--isVip：是否为VIP身份；collectShow：是否显示收藏按钮；claimShow：是否显示认领按钮；shareShow：是否显示分享按钮；-->
        <SummaryCard
          :cardType="cardType"
          :cardInfo="detailInfo"
          :isVip="userInfo && userInfo.userMember && (userInfo.userMember.key === 'vip' ||userInfo.userMember.key === 'svip') ? true:false"
          :isSVip="userInfo && userInfo.userMember && (userInfo.userMember.key === 'svip')? true:false"
          :collectShow="false"
          :claimShow="false"
          :shareShow="false"
          :detailID="brandID"
        ></SummaryCard>
         <!-- 配套信息 -->
        <div class="content-item" id="supportInfo">
          <div class="item-title">配套信息
            <Poptip trigger="hover" placement="right" padding="0 0">
              <Icon type="ios-help-circle-outline" class="help_icon" />
              <div class="m-tips" slot="content">
                <p><span>门店所在区域周边500米的配套信息</span></p>
              </div>
            </Poptip>
          </div>
          <AssortInfo :storeInfo="detailInfo" />
        </div>
         <!--业态分布-->
        <div class="content-item" id="yetai">
          <div class="item-title">
            业态分布
            <Poptip trigger="hover" placement="right" padding="0 0">
              <Icon type="ios-help-circle-outline" class="help_icon" />
              <div class="m-tips" slot="content">
                <p><span>门店所在区域周边500米的业态分布</span></p>
              </div>
            </Poptip>
          </div>
          <div class="business-size">
            <div class="business-left">
              <div
                class="left-item"
                v-for="(item, index) in echartData1"
                :key="'business_' + index"
              >
                <div class="left-item-title">{{ item.name }}</div>
                <div class="left-item-num">{{ item.value }}<span>家</span></div>
                <div class="left-item-line"></div>
              </div>
            </div>
            <div class="business-right">
              <PieChart :echartData="echartData1" class="charts-box">
              </PieChart>
            </div>
          </div>
        </div>
        <!-- 周边客群 -->
        <div class="content-item" id="juKeFenXi">
          <div class="item-title">
            周边客群
            <Poptip trigger="hover" placement="right" padding="0 0">
              <Icon type="ios-help-circle-outline" class="help_icon" />
              <div class="m-tips" slot="content">
                <p><span>门店所在区域周边500米的客群分析</span></p>
              </div>
            </Poptip>
            <!-- <img
              class="g-vip-icon"
              :src="require('@/assets/images/vip/g-vip.png')"
              width="21"
              height="10"
            /> -->
             <Icon type="ios-lock-outline"  class="g-vip-icon"/>
          </div>
          <section class="g-vip-section">
            <div role="button" class="g-vip-btn" @click="toVip">去升级</div>
            <div>
              <img
                :src="require('@/assets/images/demo_pointer.png')"
                width="464"
              />
            </div>
            <div class="diliver"></div>
          </section>
          <CircumCustomer
            v-if="detailInfo.geom"
            :id="brandID"
            identify="单品牌"
            :posInfo="detailInfo"
            @getTotal="getTotal"
          ></CircumCustomer>
          <div class="chart-title">
            聚客类型
            <Poptip trigger="hover" placement="right" padding="0 0">
              <Icon type="ios-help-circle-outline" class="help_icon" />
              <div class="m-tips" slot="content">
                <p><span>门店所在区域周边500米的聚客点主力类型</span></p>
              </div>
            </Poptip>
          </div>
          <JuKeFenXi :storeInfo="detailInfo" />
        </div>


        <!--销量分析-->
        <!-- <div class="demo-item" id="xiaoliang">
          <img src="../../assets/images/demo/store_detail_demo1.png" alt="" />
          <div class="demo-btn" @click="vipClick"></div>
        </div> -->
        <!--客流分析-->
        <!-- <div class="demo-item" id="keliu">
          <img src="../../assets/images/demo/store_detail_demo2.png" alt="" />
          <div class="demo-btn" @click="vipClick"></div>
        </div> -->
        <!--到访分析-->
        <!-- <div class="demo-item" id="daofang">
          <img src="../../assets/images/demo/store_detail_demo3.png" alt="" />
          <div class="demo-btn" @click="vipClick"></div>
        </div> -->
        <!-- 门店到访 -->
        <div class="demo-item" id="mddf">
           <Icon type="ios-lock-outline" class="g-vip-icon2" />
          <!-- <img src="../../assets/images/demo/store_detail_demo5.png" alt="" /> -->
          <img src="../../assets/images/demo/store_detail_demo5_b.png" alt="" />
          <div class="demo-btn2" @click="vipClick"></div>
        </div>
        <!--客户画像（到访）-->
        <div class="demo-item" id="kehu">
           <Icon type="ios-lock-outline" class="g-vip-icon3" />
          <!-- <img src="../../assets/images/demo/store_detail_demo6.png" alt="" /> -->
          <img src="../../assets/images/demo/store_detail_demo6_b.png" alt="" />
          <div class="demo-btn2" @click="vipClick"></div>
        </div>

        <!-- 门店销量 -->
        <div class="demo-item" id="mdxl">
           <Icon type="ios-lock-outline" class="g-vip-icon2" />
          <!-- <img src="../../assets/images/demo/store_detail_demo7.png" alt="" /> -->
          <img src="../../assets/images/demo/store_detail_demo7_b.png" alt="" />
          <div class="demo-btn2" @click="vipClick"></div>
        </div>
        <!-- 深度分析 -->
        <div class="demo-item custom-item" id="depth">
          <div class="anchor-box8" ref="offlineAnalysis" id="offlineAnalysis">
            <!--demo图片，此div用于锚点定位-->
            <offline-analysis></offline-analysis>
          </div>
          <div class="anchor-box9" id="onlineAnalysis">
            <!--demo图片，此div用于锚点定位-->
            <online-analysis></online-analysis>
          </div>
        </div>
        <div class="page-bottom"></div>
        <ModalUpgrade :isShow="isShow" @close="isShow = false"></ModalUpgrade>
      </div>
    </div>
  </div>
</template>

<script>
import ModalUpgrade from "@/components/ModalUpgrade.vue"
import SummaryCard from "../../components/SummaryCard.vue";
import MenuSide from "../../components/MenuSide.vue";
import AssortInfo from "@/components/cards/AssortInfo.vue";
import JuKeFenXi from "@/components/cards/JuKeFenXi.vue";

import { GetCommonInfo } from "@/service";
import MapPanelLittle from "@/components/MapPanelLittle.vue";
import { formatFieldsList } from "js/utils.js";
import { any2geom, geom2wkt } from "js/map/map.utils.js";
import PieChart from "../../components/chart/pieChart.vue";
import CircumCustomer from "@/components/cards/CircumCustomer.vue";
import offlineAnalysis from "../../components/depthAnalysis/offline";
import onlineAnalysis from "../../components/depthAnalysis/online";
import * as turf from "@turf/turf";
import { mapGetters } from "vuex";
export default {
  metaInfo() {
    let list = ["品牌门店"];
    if (this.storeName) {
      list.unshift(this.brandName);
      list.unshift(this.storeName);
    }
    return {
      title: list.join(" - "),
       meta: [
      {
        name: "keywords",
        content:
          `千里目,${this.storeName},${this.brandName},品牌数据,门店数据,周边客群画像,聚客类型,周边配套,周边业态,商品销量趋势,商品分类占比,到访客流画像,客流来源,客流到访时段,客流停留时长,新增流失客流,客流统计,实时客流`,
      },
      {
        name: "description",
        content:
          `${this.storeName}的门店数据分析，支持查看${this.brandName}该门店的周边客群画像、聚客类型、周边配套、周边业态、商品销量趋势和品类占比、到访实时客流画像和来源、到访时段和停留时长、新增流失客流等详细数据。`,
      },
       ]
    };
  },
  data() {
    return {
      isShow:false,
      menuOpen: true,
      brandID: "",
      storeID: "",
      brandName: "",
      storeName: "",
      detailInfo: {
        '总人口数':"",
        '总家庭户数':"",
      },

      activeMenu: "",

      // 左侧菜单列表，key值临时定义使用时需修改
      menuList: [
        {
          key: "门店洞察",
          name: "门店洞察",
          title: "门店",
          children: [
            {
              key: "supportInfo",
              name: "配套信息",
              icon: require("@/assets/images/icon/menu_pt.png"),
              isComponet: false,
            },
            {
              key: "yetai",
              name: "业态分布",
              icon: require("@/assets/images/icon/menu_yt.png"),
              isComponet: false,
            },
            {
              key: "juKeFenXi",
              name: "周边客群",
              icon: require("@/assets/images/icon/menu_jk.png"),
              isComponet: false,
            },
            // {
            //   key: "xiaoliang",
            //   name: "销量分析",
            //   icon: require("@/assets/images/icon/menu_xl.png"),
            //   isComponet: false,
            // },
            // {
            //   key: "keliu",
            //   name: "客流分析",
            //   icon: require("@/assets/images/icon/menu_kl.png"),
            //   isComponet: false,
            // },
            // {
            //   key: "daofang",
            //   name: "到访分析",
            //   icon: require("@/assets/images/icon/menu_df.png"),
            //   isComponet: false,
            // },
            {
              key: "mddf",
              name: "门店到访",
              icon: require("@/assets/images/icon/menu_mddf.png"),
              isComponet: false,
            },
            {
              key: "kehu",
              name: "客户画像",
              icon: require("@/assets/images/icon/menu_kh.png"),
              isComponet: false,
            },
            {
              key: "mdxl",
              name: "门店销量",
              icon: require("@/assets/images/icon/menu_xl.png"),
              isComponet: false,
            },
          ],
        },
        {
          key: "深度分析",
          name: "深度分析",
          title: "深度",
           children: [
            {
              key: "offlineAnalysis",
              name: "线下数据分析",
              icon: require("@/assets/images/icon/menu_xx.png"),
              isComponet: false,
            },
            {
              key: "onlineAnalysis",
              name: "线上营销分析",
              icon: require("@/assets/images/icon/menu_xs.png"),
              isComponet: false,
            },
          ],
        },
      ],

      mapMarkers: [],

      cardType: 6,

      //业态分布
      echartData1: [],
    };
  },
  watch: {},
  computed: {
    scrollBox() {
      return this.$refs.scroll;
    },
    ...mapGetters({
      userInfo: "getUserInfo",
      token: "getToken",
    }),
  },
  methods: {
    isMenuOpen(isOpen) {
      this.menuOpen = isOpen;
    },
    menuSelect(name, isComponent) {
      if (isComponent) {
        // 线下营销或非锚点定位走动态路由
      } else {
        document.getElementById(name) &&
          document.getElementById(name).scrollIntoView({ behavior: "smooth" });
      }
    },
    toVip() {
      this.isShow = true;
      // window.open("/vip", "_blank");
    },
    //获取详情信息（汇总卡片信息）
    async getDetail() {
      let params = {
        identify: "sg-data-品牌-门店详情",
        filters: [
          {
            field: "品牌id",
            cond: 0,
            value: [this.brandID],
          },
          {
            field: "门店id",
            cond: 0,
            value: [this.storeID],
          },
        ],
        fields: [
          "品牌名称",
          "门店名称",
          "门店地址",
          "城市",
          "lon",
          "lat",
          "品牌分类",
          "图片url",
          "更新时间",
          "版本号",
          "geom",
          "省名称",
          "省编码",
          "城市名称",
          "市编码",
          "区县名称",
          "区县编码",
          "门店id",
        ],
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let list = formatFieldsList(res.data);
        this.detailInfo = list[0] || {};
        this.brandName = this.detailInfo["品牌名称"];
        this.storeName = this.detailInfo["门店名称"];

        //地图数据
        let polygon = any2geom(list[0]["geom"] || "");
        let features = [];
        let feature = {
          type: "Feature",
          geometry: JSON.parse(JSON.stringify(polygon)),
        };
        features.push(feature);
        this.mapMarkers = features;

        this.getDetail2();
        this.getBusinessType();
      }
    },

    //获取品牌详情信息（汇总卡片信息）
    async getDetail2() {
      let params = {
        identify: "sg-data-品牌-品牌详情信息",
        filters: [
          {
            field: "品牌id",
            cond: 0,
            value: [this.brandID],
          },
        ],
        fields: ["人均消费", "公司名称"],
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let list = formatFieldsList(res.data);
        this.detailInfo["人均消费"] = list[0]["人均消费"] || "";
        this.detailInfo["公司名称"] = list[0]["公司名称"] || "";
        this.detailInfo = JSON.parse(JSON.stringify(this.detailInfo));
      }
    },
    //业态分布
    async getBusinessType() {
      //绘制圆形区域
      let markerObj = {
        center: [this.detailInfo["lon"], this.detailInfo["lat"]],
        type: "",
        radius: 500,
      };
      const { center, radius } = markerObj;
      let circle = turf.circle(center, radius / 1000, {
        steps: 256,
        units: "kilometers",
      });
      let searchGeom = JSON.stringify(circle.geometry);
      searchGeom = geom2wkt(searchGeom);

      let params = {
        identify: "sg-data-位置-业态分布",
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [searchGeom],
          },
        ],
        fields: [
          "美食餐饮",
          "生活服务",
          "宾馆酒店",
          "医疗卫生",
          "体育休闲",
          "金融银行",
        ],
        pages: {
          page: 1,
          size: 10000,
        },
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let list = formatFieldsList(res.data);
        for (let i in list[0]) {
          this.echartData1.push({
            name: i,
            value: list[0][i],
          });
        }
      }
    },

    //锚点和导航联动
    handleScroll() {
      //获取dom滚动距离
      const scrollTop = this.scrollBox.scrollTop;

      // 锚点
      let juKeFenXiTop = this.$el.querySelector("#juKeFenXi").offsetTop;
      // let xiaoliangTop = this.$el.querySelector("#xiaoliang").offsetTop;
      // let keliuTop = this.$el.querySelector("#keliu").offsetTop;
      // let daofangTop = this.$el.querySelector("#daofang").offsetTop;
      let kehuTop = this.$el.querySelector("#kehu").offsetTop;
      let supportInfoTop = this.$el.querySelector("#supportInfo").offsetTop;
      let yetaiTop = this.$el.querySelector("#yetai").offsetTop;
      let sevenTop = this.$el.querySelector("#depth").offsetTop;
      let sevenOneTop =
        sevenTop + this.$el.querySelector("#offlineAnalysis").offsetTop;
      let sevenTwoTop =
        sevenTop + this.$el.querySelector("#onlineAnalysis").offsetTop;
      if (scrollTop >= juKeFenXiTop && scrollTop < supportInfoTop) {
        //滚动聚客分析
        this.activeMenu = "juKeFenXi";
      } else if (scrollTop >= supportInfoTop && scrollTop < yetaiTop) {
        //滚动配套信息
        this.activeMenu = "supportInfo";
      } else if (scrollTop >= yetaiTop && scrollTop < kehuTop) {
        //滚动业态分布
        this.activeMenu = "yetai";
      // } else if (scrollTop >= xiaoliangTop && scrollTop < keliuTop) {
      //   //滚动销量分析
      //   this.activeMenu = "xiaoliang";
      }
      // else if (scrollTop >= keliuTop && scrollTop < daofangTop) {
      //   //滚动客流分析
      //   this.activeMenu = "keliu";
      // } else if (scrollTop >= daofangTop && scrollTop < kehuTop) {
      //   //滚动到访分析
      //   this.activeMenu = "daofang";
    // }
      else if(scrollTop >= kehuTop) {
        //滚动客户画像
        this.activeMenu = "kehu";
      }else if (scrollTop >= sevenOneTop && scrollTop < sevenTwoTop) {
        this.activeMenu = "offlineAnalysis";
      } else if (scrollTop >= sevenTwoTop) {
        this.activeMenu = "onlineAnalysis";
      }
    },
    // 获取总人口数
     getTotal(data){
      /* eslint-disable*/
      // console.log(data,"data")
      let obj = {
        "总人口数": data.totalNum,
        "总家庭户数":data.totalFamily
      }
      this.detailInfo = {...this.detailInfo, ...obj}
       /* eslint-disable*/
      // console.log(this.detailInfo,"this.detailInfo")
        // this.detailInfo["总人口数"] = data.totalNum;
        // this.detailInfo["总家庭户数"] = data.totalFamily
    },
    //开通VIP
    vipClick() {
      this.isShow = true
      // window.open("/vip", "_blank");
    },
  },
  mounted() {
    //获取地址栏参数
    let getID = this.$route.query.id;
    let brandID = this.$route.query.brandID;
    if (getID) {
      this.storeID = getID;
    }
    if (brandID) {
      this.brandID = brandID;
    }
    //获取详情信息
    this.getDetail();
    this.scrollBox.addEventListener("scroll", this.handleScroll);
  },
  components: {
    SummaryCard,
    AssortInfo,
    JuKeFenXi,
    MenuSide,
    MapPanelLittle,
    PieChart,
    CircumCustomer,
     offlineAnalysis,
    onlineAnalysis,
    ModalUpgrade
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/stylesheets/table.less";

.detail-main {
  display: flex;
  flex-wrap: wrap;

  .main-scroll {
    width: calc(100% - 200px);
    height: calc(100% - 101px);
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: auto;
    position: fixed;
    right: 0;
    bottom: 0;
    background: #f6f6fa;
    transition: width 0.3s;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;

    .map-size {
      width: 100%;
      height: 200px;
      background: #d7d7d7;
    }

    .main-size {
      width: 1120px;
      min-width: 1120px;
      margin-top: -62px;
      position: relative;
      z-index: 199;
    }

    .content-item {
      width: 100%;
      height: auto;
      position: relative;
      margin: 18px 0 0 0;
      width: 100%;
      height: auto;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      .item-title {
        width: 100%;
        height: 56px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        box-sizing: border-box;
        padding: 0 0 0 20px;
        border-bottom: 1px solid #e0e0e0;
      }

      .business-size {
        width: 100%;
        height: 273px;
        display: flex;
        box-sizing: border-box;
        padding: 0 30px;

        .business-left {
          width: 500px;
          height: 160px;
          background: #fbfbfe;
          border-radius: 8px;
          border: 1px solid rgba(0, 0, 0, 0.02);
          margin: 56px 30px 0 0;
          display: flex;
          flex-wrap: wrap;

          .left-item {
            width: 33.33%;
            height: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            align-content: center;
            position: relative;

            .left-item-title {
              width: 100%;
              text-align: center;
            }

            .left-item-num {
              width: 100%;
              text-align: center;
            }

            .left-item-line {
              width: 1px;
              height: 25px;
              background: rgba(132, 130, 149, 0.2);
              position: absolute;
              top: 30px;
              right: 0;
            }
          }

          .left-item:nth-child(3n) {
            .left-item-line {
              display: none;
            }
          }
        }

        .business-right {
          width: calc(100% - 560px);
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .charts-box {
            width: 100%;
            height: 70%;
          }
        }
      }
    }

    .demo-item {
      width: 1128px;
      margin: 15px 0 0 -4px;
      display: flex;
      position: relative;
       .g-vip-icon2{
        position: absolute;
        left: 114px;
        top: 18px;
        font-size: 16px;
      }
      .g-vip-icon3{
         position: absolute;
        left: 164px;
        top: 18px;
        font-size: 16px;
      }
      img {
        width: 100%;
      }
      .demo-btn {
        width: 200px;
        height: 45px;
        position: absolute;
        top: 122px;
        left: 465px;
        cursor: pointer;
      }
      .demo-btn2 {
        width: 200px;
        height: 45px;
        position: absolute;
        top: 389px;
        left: 464px;
        cursor: pointer;
      }
    }
    .custom-item {
      flex-direction: column;
      img {
        width: auto;
      }
    }

    .page-bottom {
      width: 100%;
      height: 60px;
    }
  }

  .main-scroll-change {
    width: calc(100% - 74px);
    transition: width 0.3s;
  }
}
.m-tips {
  padding: 16px;
  font-size: 12px;
}
.chart-title {
  width: 100%;
  box-sizing: border-box;
  padding: 25px 30px 0 30px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  border-top: 1px solid #eeeeee;
  display: flex;
  align-items: center;
}
::v-deep .ivu-poptip {
  margin-left: 6px;
}
</style>
