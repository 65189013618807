//配套信息
<template>
  <div class="assort-info">
    <div class="left">
      <MapPanel
        :id="mapId"
        ref="mapPanel"
        :mapTools="mapTools"
        :legendsObj="legendsObj"
        :ignoreFitChina="true"
        @loaded="handleMapLoaded"
        @selectCity="handleSelectCity"
        @startDraw="handleStartDraw"
        @drawPoint="handleDrawPoint"
        @drawPolygon="handleDrawPolygon"
        @searchPoi="searchPoi"
      >
      </MapPanel>
    </div>
    <div class="right">
      <SupportingFacilities :polygon="resultGeom" :address="markerAddress" />
    </div>
  </div>
</template>
<script>
import { GetCommonInfo, GetCurLocation } from "@/service";
import { cloneObj, formatFieldsList2 } from "js/utils.js";
import { geom2wkt, wkt2geom } from "js/map/map.utils.js";

import MapPanel from "@/components/MapPanel.vue";
import SupportingFacilities from "@/components/location/SupportingFacilities.vue";
import * as turf from "@turf/turf";

export default {
  name: "assortInfo",
  props: {
    storeInfo: {
      type: Object,
      required: true,
      default: () => {
        return {
          geom: "0101000020E6100000577C43E1B3555E408D9944BDE0173F40",
          lat: "31.093273",
          lon: "121.339104",
          区县名称: "松江区",
          区县编码: "310117",
          品牌分类: "餐饮/小吃快餐/快餐简餐",
          品牌名称: "沙县小吃",
          图片url:
            "https://shuguan-assets.changjing.com.cn/data/brand-v2/67022880-6c6e-4e52-ba43-f0bab992e71d.jpg",
          城市: "上海市",
          城市名称: "上海市",
          市编码: "310100",
          更新时间: "2022-08-17",
          版本号: "v3.2",
          省名称: "上海市",
          省编码: "310000",
          门店id: "3df842df-e472-49d5-8102-cde068257f9e",
          门店名称: "沙县小吃",
          门店地址: "莘松路1464号",
        };
      },
    },
  },
  computed: {
    legendsObj() {
      return {
        title: "全部配套",
        list: this.legends,
      };
    },
    resultGeom() {
      if (this.polygon) {
        return geom2wkt(this.polygon);
      }
      return "";
    },
  },
  watch: {
    storeInfo(val) {
      if (this.isMapLoaded) {
        this.initMapData(val);
      }
    },
  },
  data() {
    return {
      mapId: new Date().getTime(),
      mapTools: [],
      legends: [
        {
          title: "住宅区",
          color: "#5F6AEB",
          key: "community",
        },
        {
          title: "商业区",
          color: "#986FFF",
          key: "business",
        },
        {
          title: "写字楼",
          color: "#FD767D",
          key: "office",
        },
        {
          title: "学校",
          color: "#FF9800",
          key: "school",
        },
        {
          title: "医院",
          color: "#FCC44D",
          key: "hospital",
        },
        {
          title: "地铁站",
          color: "#3CD19C",
          key: "subway",
        },
        {
          title: "公交站",
          color: "#28C7F3",
          key: "bus",
        },
      ],
      isMapLoaded: false,

      polygon: null,
      markerObj: {
        center: [116.309834, 40.042785],
        type: "",
        radius: 500,
      },
      markerAddress: "",
    };
  },
  methods: {
    //地图加载完成后
    handleMapLoaded() {
      this.isMapLoaded = true;
      //兼容，当地图加载完成后，但是storeInfo数据没有加载出来，导致后续渲染地图报错
      if (Object.keys(this.storeInfo).length > 0) {
        this.initMapData();
      }
    },
    // 地图绘制之前清空
    handleStartDraw() {
      let layerIds = ["marker_point", "marker_circle", "polygons"];
      for (let i = 0, n = layerIds.length; i < n; i++) {
        let layerId = layerIds[i];
        this.mapPanel.changeLayerShow(layerId, false);
      }
      this.mapPanel.closeWindowInfo();
      this.mapPanel.clearLegends();
      this.markerAddress = "";
    },
    //更新map数据
    initMapData() {
      const storeInfo = this.storeInfo;
      this.markerObj.center = [Number(storeInfo.lon), Number(storeInfo.lat)];
      const feature = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: this.markerObj.center,
        },
        propertite: {},
      };
      this.handleDrawPoint(feature);
    },
    // 绘制点位
    async handleDrawPoint(feature) {
      if (!feature.properties) {
        feature.properties = {};
      }
      feature.properties.type = "drawMarker";
      feature.properties.drawPoint = true;
      let features = [feature];
      let options = {
        icon: "/images/marker_blue2.png",
        iconSize: [22, 33],
        anchor: "bottom-center",
      };
      this.mapPanel.setMarkers("marker_point", features, options);

      let center = feature.geometry.coordinates;
      this.markerObj.type = "surround";
      this.drawMarkerPolygon();

      let infoObj = {
        center,
        title: "",
        address: "",
      };
      let res = await GetCurLocation({
        lon: center[0],
        lat: center[1],
      });
      if (res && res.code === 200) {
        let data = res.data.items;
        infoObj.title = data.building || data.cityName;
        infoObj.address = data.formattedAddress;

        this.markerAddress = infoObj.address + "，区域面积0.79km²";
      }
      this.queryLegendsPois();

      // this.openPointInfowindow(infoObj);
    },
    // 绘制面
    handleDrawPolygon(feature) {
      let features = [feature];
      let options = {};
      this.mapPanel.setPolygons("polygons", features, options);
      this.mapPanel.fitBounds(features, true, [100, 100, 100, 100]);
      this.searchGeom = JSON.stringify(feature.geometry);
      this.openPolygonInfoWindow(feature);
    },
    //绘制点位相关的圆
    drawMarkerPolygon() {
      const { center, radius } = this.markerObj;
      let features = [];

      let circle = turf.circle(center, radius / 1000, {
        steps: 256,
        units: "kilometers",
      });
      this.polygon = JSON.stringify(circle.geometry);

      circle.properties.drawPoint = true;
      features = [circle];

      let options = {};
      this.mapPanel.setPolygons("marker_circle", features, options);
      if (features.length > 0) {
        this.mapPanel.fitBounds(features, true, [100, 100, 100, 100]);
      }
      this.mapPanel.clearLegends();
    },
    // 查询poi
    queryLegendsPois() {
      let geom = this.polygon;
      for (let i = 0, n = this.legends.length; i < n; i++) {
        this.queryAreaPois(i, geom);
      }
      this.isSearchResult = true;
    },
    // 请求选中面积中的配套信息
    async queryAreaPois(legendIndex, geom) {
      let key = this.legends[legendIndex].key;
      let identify = this.$store.state.tabsIdentifyLocation[key];
      let fields = cloneObj(this.$store.state.tabsLocationFields[key]);
      fields.push("位置");
      let polygon = geom2wkt(geom);
      let params = {
        identify: identify,
        fields: fields,
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [polygon],
          },
        ],
        pages: {
          page: 1,
          size: 10000,
        },
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let list = formatFieldsList2(res.data);

        let features = [];
        for (let i = 0, n = list.length; i < n; i++) {
          let item = list[i];
          let attrs = [];
          for (let key in item) {
            if (key === "位置") {
              continue;
            }
            attrs.push({
              label: key,
              value: item[key],
            });
          }
          item.attrs = attrs;
          item.isPoi = true;
          let geom = item["位置"];
          if (!geom) {
            continue;
          }
          geom = wkt2geom(geom);
          let feature = {
            type: "Feature",
            properties: item,
            geometry: geom,
          };
          features.push(feature);
        }
        // console.log("list",list,fields,features,legendIndex)
        this.mapPanel.setLegendSource(legendIndex, features);
      }
    },

    async handleSelectCity(city) {
      let cityCode = city.adcode;
      let district = await this.mapPanel.searchDistrict(cityCode, true);
      this.mapPanel.fitBounds(district.bbox);
    },
    searchPoi(searchPoi) {
      let center = searchPoi.center;
      let feature = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: center,
        },
      };
      this.handleDrawPoint(feature);
    },
  },
  mounted() {
    this.mapPanel = this.$refs.mapPanel;
  },
  components: {
    MapPanel,
    SupportingFacilities,
  },
};
</script>
<style lang="less" scoped>
.assort-info {
  padding: 30px;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .left {
    width: 640px;
    height: 583px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid #e3e3e3;
    border-right-color: transparent;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
  }
  .right {
    width: 420px;
    height: 583px;
    border: 1px solid #eeeeee;
    padding: 22px;
    border-left-color: transparent;
    box-sizing: border-box;
    overflow: auto;
  }
}
</style>
